import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import background from '../../img/slider/slider-2.jpg'
import banner from '../../img/shape/BG-1.png'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios'

export default class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorlastname: '',
            errorfirstname: '',
            errorphone: '',
            erroremail: '',
            errorgraduation_date: '',
            errorcity: '',
            success:''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    handleSubmit(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({errorlastname: ''});
        this.setState({errorfirstname: ''});
        this.setState({errorphone: ''});
        this.setState({erroremail: ''});
        this.setState({errorgraduation_date: ''});
        this.setState({errorcity: ''});
        if(target.lastname.value ==''){
            return this.setState({errorlastname: 'nom est obligatoire'});
          }
          if(target.firstname.value ==''){
            return this.setState({errorfirstname: 'prénom est obligatoire'});
          }
          if(target.phone.value ==''){
            return this.setState({errorphone: 'téléphone est obligatoire'});
          }
          if(target.email.value ==''){
            return this.setState({erroremail: 'email est obligatoire'});
          }
          if(target.graduation_date.value ==''){
            return this.setState({errorgraduation_date: "Année est obligatoire"});
          }
          if(target.city.value ==''){
            return this.setState({errorcity: 'Ville est obligatoire'});
          }
          const lead={
              'lastname':target.lastname.value,
              'firstname':target.firstname.value,
              'phone':target.phone.value,
              'email':target.email.value,
              'graduation_date':target.graduation_date.value,
              'city':target.city.value,
              'formation':'AERO'
          }
          console.log(lead);
                axios.get('https://bacheliers.uirservices.ma/inscription-new.php')
                .then(res=>console.log(res))
                .catch(err=>console.log(err))
          this.setState({success: 'Merci de nous avoir contacté, nous avons bien reçu vos informations'});
          target.reset();
          window && window.dataLayer && window.dataLayer.push({
            'event':'form_lead_submit',
            'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
        });
      }
  render() {
    return (
        <>
            <div className="single-slider swiper-slide slider__height d-flex align-items-center" style={{ backgroundImage: "url(" + { background } + ")" }}>
                <div className="container pb-10">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6  col-md-6  col-sm-12">
                            <div className="slider__content pb-80 content">
                                <h3 className="slider__title">#BGHIT <span className="yellow-bg">NKOUN <img src={banner} alt="img not found" /> </span>INGÉNIEUR</h3>
                                <span>Rejoignez l'école d'ingénierie aérospatiale et automobile</span>
                                <span className="text-white">Diplôme Reconnu par l'État</span>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                        <form method="POST"  className='formHeader'  onSubmit={this.handleSubmit}>
                            <input type="text" name="formation" className='d-hidden' value="AERO" />
                            <h2 className='text-center titleform'>Candidats Bacheliers </h2>
                            {this.state.success
                                ? <div className='text-success px-5'> {this.state.success} </div>
                                :
                                ''
                            }
                            <div className='row'>
                                <div className='col-md-6 mt-2'>
                                    <TextField id="outlined-basic" label="Nom" name="lastname" variant="outlined" fullWidth className='bg-input' />
                                    {this.state.errorlastname
                                         ? <span className='text-danger'> {this.state.errorlastname} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <TextField id="outlined-basic" label="Prénom" name="firstname" variant="outlined" fullWidth className='bg-input' />
                                    {this.state.errorfirstname
                                         ? <span className='text-danger'> {this.state.errorfirstname} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <TextField id="outlined-basic" label="Téléphone" name="phone" variant="outlined" fullWidth className='bg-input' />
                                    {this.state.errorphone
                                         ? <span className='text-danger'> {this.state.errorphone} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <TextField id="outlined-basic" label="Email" name="email" variant="outlined" fullWidth className='bg-input' />
                                    {this.state.erroremail
                                         ? <span className='text-danger'> {this.state.erroremail} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        className='bg-input'
                                        options={annee}
                                        renderInput={(params) => <TextField {...params} label="Année d'obtention BAC" name="graduation_date" />}
                                    />
                                    {this.state.errorgraduation_date
                                         ? <span className='text-danger'> {this.state.errorgraduation_date} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Autocomplete
                                        disablePortal
                                        className='bg-input'
                                        id="combo-box-demo"
                                        options={villes}
                                        renderInput={(params) => <TextField {...params} label="Ville" name="city" />}
                                    />
                                      {this.state.errorcity
                                         ? <span className='text-danger'> {this.state.errorcity} </span>
                                        : ''
                                    }
                                </div>
                                <div className='col-md-12 mt-2'>
                                <FormControlLabel control={<Checkbox  />} 
                                label= {
                                    <div>
                                       <span>J’ai lu et j’accepte</span>
                                       <a href='https://www.uir.ac.ma/fr/page/Mentions-legales' target="_blank" className='fw-bold'> mentions légales</a>
                                    </div>
                                    }
                                />
                                </div>
                                <div className='col-md-12 text-center mt-2'>
                                    <button className="g-btn mt-2 w-80 fs-4" type='submit'> Je m’inscris</button>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-3 col-12 col-lg-3">
                    <div className="slider__nav-item swiper-slide item1bg item-1">
                        <div className="slider__nav-content">
                            <h4>Acquérir des compétences <br />scientifiques, techniques<br /> et professionnelles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-12col-lg-3"> 
                    <div className="slider__nav-item swiper-slide blue-bg item-2" >
                        <div className="slider__nav-content">
                            <h4>S’adapter au milieu <br />industriel dans<br /> le domaine de l’automobile</h4>
                        </div>
                    </div>
                </div>    
                <div className="col-md-3 col-12 col-lg-3">
                    <div className="slider__nav-item swiper-slide pink-bg item-3" >
                        <div className="slider__nav-content">
                            <h4>Être apte au travail<br /> en groupe et<br /> à la communication</h4>
                        </div>
                    </div>
                 </div>   
                <div className="col-md-3 col-12 col-lg-3">
                <div className="slider__nav-item swiper-slide green-bg item-4">
                        <div className="slider__nav-content">
                            <h4>Être capable <br /> de résoudre des <br />  problèmes industriels</h4>
                        </div>
                    </div>
                </div>
                </div>

            </div>
          
        </>
    );
  }
}
const annee = [
    "Année d'obtention BAC",
    "2021/2022 ",
    "2020/2021 ",
    "2019/2020 ",
    "2018/2019 ",
    "2017/2018 ",
    "Autre",
];
const villes=[
    "Agadir",
"Ahfir",
"Alhoceima",
"Asilah",
"Azilal",
"Azrou",
"Beni Mellal",
"Berkane",
"Berrechid",
"Boujaad",
"Boujdour",
"Casablanca",
"Chefchaouen",
"Dakhla",
"El_Jadida",
"Erfoud",
"Er_rachidia",
"Essaouira",
"Fes",
"Figuig",
"Fquih ben saleh",
"Guelmim",
"Ifrane",
"Jerrada",
"Kasbat_tadla",
"Kenitra",
" Khemisset",
"Khenifra",
"Khouribga",
"Ksar el kbir",
"Laayoune",
"Lagouira",
"Larache",
"Marrakech",
"Meknes",
"Merzouga",
"Midelt",
"Mohammedia",
"Moulay Bousselham",
"Nador",
"Oualidia",
"Ouarzazate",
"Ouedzem",
"Ouazzane",
"Oujda",
"Oulmes",
"Rabat",
"Rissani",
"Safi",
"Salé",
"Sefrou",
"Settat",
"Sidiifni",
"Sidi kacem",
"Sidi slimane",
"Tanger",
"Tantan",
"Taroudant",
"Tata",
"Taza",
" Temara",
"Tétouan",
"Tiflet",
"Tineghir",
"Tiznit",
"Zagora",
"Autres",
]