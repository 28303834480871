import React from 'react'
import Header from '../components/aero/Header'
import Banner from '../components/aero/Banner'
import Service from '../components/aero/Serivce'
import Partenaire from '../components/aero/Partenaire'
import Avis from '../components/aero/Avis'
import Chiffres from '../components/aero/Chiffres'
import Contact from '../components/aero/Contact'

export default function aero() {
  return (
    <>
    <Header />
    <Banner />
    <Service />
    <Partenaire />
    <Avis />
    <Chiffres />
    <Contact />
    </>
  )
}
